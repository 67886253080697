<template>
  <div>
    <div class="page-content py-5">
      <div class="container">
        <div class="page-content py-5 overflow-hidden">
          <div class="container">
            <div class="col-12 col-xl-6 col-lg-6 col-md-6 m-auto">
              <div class="404-contener text-center">
                <div class="mx-auto mb-4">
                  <img src="@/assets/images/404@2x.png" alt="404" />
                </div>
                <div>
                  <h4 class="d-block">{{ $t("message.error404") }}</h4>
                  <form action="">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control rounded-pill rounded-start"
                        :placeholder="$t('label.keywords')"
                        aria-describedby="basic-addon1"
                      />
                      <span
                        class="
                          input-group-text
                          edc-bg-primary
                          rounded-pill rounded-end
                        "
                        id="basic-addon1"
                      >
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      titleTemplate: this.$t("title.not_found") + " | %s",
      meta: [{ property: "og:title", content: this.$t("title.not_found") }],
    };
  },
};
</script>